import React, {useEffect, useState} from 'react';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import {useHistory, useLocation} from 'react-router-dom';
import {Row, Col, Form, Input, notification, Checkbox, Modal, Tabs} from 'antd';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import LoginStyles, {Logo} from '../styles';
import LogoFonRadar from '../../../assets/logo-big.png';
import userApi from '../../../Api/User';
import paths from '../../../routes/paths';

import ClarificationText from '../../Documents/ClarificationText';
import ExplicitConsentText from '../../Documents/ExplicitConsentText';
import {validatePassword} from '../../../utils/validators';
import illuminationText from '../../../assets/aydinlatma-metni.pdf';
import expressConsent from '../../../assets/kvkk-acik-riza-beyani.pdf';
import NotoFont from '../../../assets/NotoSerif-Regular.ttf' ;

const PasswordRecovery  = () => {

  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [register, setRegister] = useState(true);
  const [tabShow, setTabShow] = useState(1); 
  const [loading, setLoading] = useState(false);
  const [illuminationTextData, setIlluminationTextData] = useState('');
  const [expressConsentData, setExpressConsentData] = useState('');
  const [name, setName] = useState('');

  const { TabPane } = Tabs;
  const history = useHistory();
  const location = useLocation();
  

  const onChangeCheck = () => {
    setRegister(!register)
  };

const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChangeTab = (key:any) => {
    setTabShow(key)
  };

  const showModal = (keyX:any) => {
    setIsModalOpen(true);
     setTabShow(keyX)
  };

const toBase64 = (u8:any) => btoa(u8.reduce((data:any, byte:any) => data + String.fromCharCode(byte), ''));

const sendEmail = async () => {
  const date = new Date();

  const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
  const illuminationTextPdfBytes = await fetch(illuminationText)
  .then((res) => res.arrayBuffer())
  .then((arrayBufferData) => arrayBufferData);
  if (illuminationTextPdfBytes) {
    const pdfDocIlluminationText = await PDFDocument.load(illuminationTextPdfBytes, {
        updateMetadata: false,
    });

    pdfDocIlluminationText.registerFontkit(fontkit);
    const customFont = await pdfDocIlluminationText.embedFont(fontBytes)

    const pagesOne = pdfDocIlluminationText.getPages();
    pagesOne[3].moveTo(40, 320);
    pagesOne[3].drawText(moment(date.toDateString()).format('DD/MM/YYYY') , {
          size: 10,
    });
    pagesOne[3].moveTo(40, 306);
    pagesOne[3].drawText( name , {
          size: 10,
          font: customFont,
    });
    
    const illuminationTextPdf = await pdfDocIlluminationText.save();
    setIlluminationTextData(toBase64(illuminationTextPdf))
}

const expressConsentPdfBytes = await fetch(expressConsent)
.then((res) => res.arrayBuffer())
.then((arrayBufferData) => arrayBufferData);
  if (expressConsentPdfBytes) {
    const pdfDocExpressConsent = await PDFDocument.load(expressConsentPdfBytes, {
        updateMetadata: false,
    });
    
    pdfDocExpressConsent.registerFontkit(fontkit);
    const customFont = await pdfDocExpressConsent.embedFont(fontBytes)

    const pagesTwo = pdfDocExpressConsent.getPages();
    pagesTwo[0].moveTo(100, 202);
    pagesTwo[0].drawText(moment(date.toDateString()).format('DD/MM/YYYY') , {
        size: 10,
    });
    pagesTwo[0].moveTo(120, 180);
    pagesTwo[0].drawText( name , {
        size: 10,
        font: customFont,
    });

    const expressConsentPdf = await pdfDocExpressConsent.save();
    setExpressConsentData(toBase64(expressConsentPdf));
  }
 
}

const sendTermsEmail = async () => {
  const terms : any = [];
  terms.push(
    {
      base64File: illuminationTextData,
      fileName: 'Neo Elektronik - Kişisel Verilere İlişkin Aydınlatma Metni.pdf'
    },
    {
      base64File: expressConsentData,
      fileName: 'Neo Elektronik - KVKK ile İlgili Açık Rıza Beyanı.pdf'
    }
  )
 
    try{
      const response:any = await userApi.sendTermsEmail(location.pathname.split('/')[3], {terms})
      if(response?.status === 204){
          setLoading(false);
          notification.success({
            message: 'Başarıyla şifre oluşturdunuz!',
            description: 'Yeni şifrenizi kullanarak giriş yapabilirsiniz.',
          });
          setTimeout(() => {  
            history.push(paths.login);
          },1000)
      }
    }catch(e:any){
      // console.log(e)
    }
}



  const onUpdatePass = async (formValues: {newPassword: string; confirmPassword: string}) => {
    try {
      setLoading(true);
      const {newPassword, confirmPassword} = formValues;
      if (newPassword && confirmPassword && newPassword === confirmPassword) {
        if (location.pathname.split('/')[2] === 'create') {
            const response =  await userApi.updatePassword({
            passwordUser: location.pathname.split('/')[3],
            passwordToken: location.pathname.split('/')[4],
            newPassword,
          });
          if(response?.status === 200){
            setName(response?.data?.username)
          }
        } 
      } else {
        setLoading(false);
        notification.error({
          message: 'Şifre ve şifreyi onayla eşleşmiyor',
          duration: 3,
        }); 
      }
    } catch(e:any){
        if(e.status === 400 && e.data.Messages ){
          const length = e.data.Messages.length - 1;
          let x = 0;
          for( x=0; x < length + 1 ; x+=1){
              notification.warning({
                  message: ` ${e.data.Messages[x]}`  
              });
          } 
        }
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(name !== ''){
      sendEmail();
    }
   },[name])
 

  useEffect(() => {
   if(illuminationTextData !== '' && expressConsentData !== ''){
    sendTermsEmail()
   }
  },[illuminationTextData,expressConsentData])

  return (
    <LoginStyles>
      <Row justify="center">
        <Col xs={24} md={12} lg={10}>
          <Card>
            <Logo className="mb-big" src={LogoFonRadar} alt="Depar.io" />
            <Text type="h3" color="primary">
              Şifre Oluştur 
            </Text>
            <p>Lütfen belirlemiş olduğunuz yeni şifrenizi onaylamak için iki defa giriniz.</p>

            <Form name="passwordRecovery" onFinish={onUpdatePass}>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password type="password" placeholder="Yeni Şifre" />
              </Form.Item>
              <Form.Item
                style={{margin: '0px'}}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password type="password" placeholder="Yeni Şifreyi Tekrarla" />
              </Form.Item>

              <div className='contract-modal'>
                  <Checkbox onChange={onChangeCheck}> </Checkbox>
                   <Button onClick={() => showModal(1)}>Aydınlatma Metni</Button>&apos;ni ve <Button onClick={() => showModal(2)}> Açık Rıza Metni</Button>&apos;ni okudum, anladım ve onaylıyorum. Kurumunuz ile paylaşmış olduğum iletişim bilgilerim ile elektronik iletiletilerin tarafımla paylaşılmasını onaylıyorum.
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  disabled={register }
                  block
                >
                  Şifremi Oluştur
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal className='contract-modal-style'  footer={false} open={isModalOpen} onCancel={handleCancel}>
          <Tabs activeKey={`${tabShow}`}  onChange={onChangeTab}>
            <TabPane tab="Aydınlatma Metni" key='1'>
                <ClarificationText/>
            </TabPane>
            <TabPane tab="Açık Rıza Metni" key='2'>
                <ExplicitConsentText/>
            </TabPane>
          </Tabs>
      </Modal>
    </LoginStyles>
  );
};

export default PasswordRecovery;
