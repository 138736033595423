// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
import {notification} from 'antd';
import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import paths from '../routes/paths';
import LocalStorageService from './LocalStorageService';


class Interceptor {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance, isFonradarApi: boolean) {
    this.axiosInstance = axiosInstance;

    this.setRequestInterceptor(isFonradarApi);
    this.setResponseInterceptor();
  }

  setRequestInterceptor(isFonradarApi: boolean): void {
    this.axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const authToken = LocalStorageService.getAuthToken();

        if (isFonradarApi) {
          if (authToken) {
            // config.headers['x-token'] = authToken;
            config.headers.Authorization = `Bearer ${authToken}`;
          }
          config.headers.common.withCredentials = false;
          config.headers.common.Accept = 'fund/json';
        }

        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  setResponseInterceptor(): void {
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.data && response.data.message) {
          notification.info({message: response.data.message});
        }
        // if (response.headers && response.headers && response.headers['x-token']) {
        //   LocalStorageService.setAuthToken(response.headers['x-token']);
        // }
        if (response.data && response.data.token) {
          LocalStorageService.setAuthToken(response.data.token);
        }

        if (response.data) {
          if (response.data && response.data.data && response.data.data.message)
            notification.success({message: response.data.data.message});
          return response;
        }

        return response;
      },

   
      ({response: error}) => {
        if (error && (error.status === 500 || error.data.status === 500)) {
          notification.error({message: 'Beklenmedik bir hata oluştu!'});
          LocalStorageService.removeAuthToken();
          LocalStorageService.removeNumber();
          LocalStorageService.removeSize();
          LocalStorageService.removeStatusCode();
          LocalStorageService.removeStatusCode();
          LocalStorageService.removeTaxId();
          if (window.location.pathname !== paths.login) {
            window.location.href = paths.login;
          }  
        } 
        if (error && (error.status === 401 || error.data === 'The token is not valid it maybe expired or not exist!')) {
          LocalStorageService.removeAuthToken();
          LocalStorageService.removeNumber();
          LocalStorageService.removeSize();
          LocalStorageService.removeStatusCode();
          LocalStorageService.removeStatusCode();
          LocalStorageService.removeTaxId();
          if (window.location.pathname !== paths.login) {
              window.location.href = paths.login;
          }  
        } 
        return Promise.reject(error);
      },
    );
  }
}

export default Interceptor;
