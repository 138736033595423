import React from 'react';
import { useSelector} from 'react-redux';
import {Col, Row } from 'antd';
import Card from '../../components/Card';
import Text from '../../components/Text';


const Home: React.VFC = () => {
  const {user} = useSelector((state: any) => state.user);

  return (
    <>
      <Card>
        <Row gutter={20} align="middle" style={{marginBottom:'50px'}}>
          <Col>
            <Text className="m-0" type="h1" color="primary" bold>
            Merhaba { user !== '' ? `${user.name} ${user.surname}` : ''} 
            </Text>
            <p>Gerçekleştirmek istediğiniz işlemi yapmak için yukarıdaki menüden seçim yapınız.</p>
          </Col>
        </Row> 
      
      </Card>
    </>
  );
};

export default Home;
