import React from 'react';
import { Col, Row, Form, Input, notification } from 'antd';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import userApi from '../../../Api/User';
import { validatePassword } from '../../../utils/validators';

const PasswordUpdate:React.VFC = () => {
    const [form] = Form.useForm();

    const sendCode = async ( values:any ) => {
        try{
            if(values.newPassword === values.confirmNewPassword){
                const response =  await userApi.passwordUpdate(values);
                if(response.status === 204){
                    notification.success({
                        message: `Başarıyla şifreniz güncellenmiştir.`,
                    });
                    form.resetFields(); 
                }
            }else{
                notification.warning({
                    message: `Yeni şifre ile şifre onayınız aynı olmalıdır.`,
                });
            } 
        }catch(e:any){
            if(e.data.Status === 400 && e.data.Type.includes('IncorrectOldPasswordException')){
                notification.error({message: 'Eski şifreniz yanlıştır.'});
            }
            if(e.data.Status === 409 && e.data.Type.includes('NewPasswordCannotBeOldPasswordException')){
                notification.error({message: 'Yeni şifre eski şifrenizle aynı olamaz.'});
            }
            if(e.data.Status === 409 && e.data.Type.includes('LastThreePasswordMatchedException')){
                notification.error({message: 'Yeni parola son üç parola ile aynı olamaz.'});
            }
        }   

       
    };

    return (
        <Row>
            <Col xs={24} md={14} lg={7} style={{ margin: '30px auto' }}>
                <Text  style={{  marginBottom:'20px'}} className="page-title">
                    Şifre Değiştir
                </Text>  
                 <Text style={{  marginBottom:'20px', display:'block', marginTop:'20px'}}>Yeni şifre oluşturunuz.</Text>
             
                <Form 
                    name="sendCode" 
                    onFinish={sendCode}
                    className="d-block email-input" 
                    labelCol={{ span: 5 }}
                    layout="horizontal"
                    form={form}>
                        <Form.Item
                            name="oldPassword"
                                rules={[
                                {
                                 required: true,
                                 message: 'Değiştirmek istediğiniz şifrenizi giriniz.' 
                                },
                                ]}
                                >
                            <Input.Password placeholder="Mevcut Şifreniz" />                      
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => validatePassword(value),
                                    },
                                ]}
                                >
                            <Input.Password placeholder="Yeni Şifre" />                      
                        </Form.Item>
                        <Form.Item
                            name="confirmNewPassword"
                            rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => validatePassword(value),
                                    },
                                ]}
                                >
                            <Input.Password placeholder="Yeni Şifre Tekrarla" />                      
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                style={{width:'200px', margin:'0 auto', display:'block'}}
                                // loading={isResetPasswordSendCodeLoading}
                                block>
                                    Gönder
                            </Button>
                        </Form.Item>
                    </Form>

            </Col>
        </Row>
    );
}

export default PasswordUpdate;
