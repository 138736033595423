import React, {useEffect, useState} from 'react';
import {Row, Table, ConfigProvider} from 'antd';
import moment from 'moment';
import 'moment/locale/tr';
import locale from 'antd/lib/locale/tr_TR';
import {useHistory} from 'react-router-dom';
import {CSVLink} from 'react-csv';
import { useSelector} from 'react-redux';
import farmerApi from '../../Api/Farmer';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Text from '../../components/Text';
import {fundStatusMap2Value} from '../../constants/funds';
import {convertToDotSeperated} from '../../utils/string';
import paths from '../../routes/paths';
import {Cardpage } from './styles';
import LocalStorageService from '../../services/LocalStorageService';


const FinancialInstitiutions: React.VFC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const {user} = useSelector((state: any) => state.user);
  const [pSize, setPsize] = useState<any>(10);
  const [pNumber, setpNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1); 

  const [farmerDiscountInvoice, setFarmerDiscountInvoice] = useState<any>([])
  
  const getAgriculturistDiscount = async () => {
    try {
      setIsLoading(true);
      const response = await farmerApi.getAgriculturistDiscountApi(pSize, pNumber);
      if (response) {
        setIsLoading(false);
        setFarmerDiscountInvoice(response?.data?.discountApplicationOffers?.data);
        setTotalDataCount(response?.data?.discountApplicationOffers?.totalDataCount)
      } else {
        setIsLoading(false);
      }

    } catch (e) {
      setIsLoading(false);
    }
  };

  const navigateFinancialDetail = (fund: any) => {
    LocalStorageService.setStatusCode(fund.statusCode)
    return history.push(
      paths.getFarmerRequestsDetails(fund.discountApplicationId)
    );
  };

    
  const handleShowSize = (current:any, size:any) => {
    setpNumber(current);
    setPsize(size);
  };

  useEffect(() => {  
    getAgriculturistDiscount();
  }, [user,pSize, pNumber]);
 

  const tableCols: any = [
    {
      title: 'Başvuru No',
      dataIndex: 'applicationNumber',
      key: 'applicationNumber',
      fixed: 'left',
      width:100
    },
    {
      title: 'Başvuru Tarihi',
      dataIndex: 'discountApplicationDate',
      render: (date: any) => moment(date).format('DD-MM-YYYY'),
      key: 'discountApplicationDate',
    },
    {
      title: 'Fatura Toplamı',
      dataIndex: 'invoicesTotal',
      key: 'invoicesTotal',
      render: (price: number) => `${convertToDotSeperated(price)} ₺`,
    },
    {
      title: 'Fatura Adedi',
      dataIndex: 'invoicesCount',
      key: 'invoicesCount',
      align: 'center'
    },
    {
      title: 'Alıcı',
      dataIndex: 'buyerTitle',
      key: 'buyerTitle',
    },
    {
      title: 'Ortalama Vade',
      dataIndex: 'averageMaturity',
      key: 'averageMaturity',
      align: 'center'
    },
    {
      title: 'İnceleyen',
      dataIndex: 'financialInstitutionUserName',
      key: 'financialInstitutionUserName',
    },
    {
      title: 'Talep Durumu',
      dataIndex: 'statusCode',
      render: (status: any) => {
        return (
          fundStatusMap2Value[status] && (
            <Text style={{fontSize: 13, color: fundStatusMap2Value[status].color}} bold>
              {fundStatusMap2Value[status].text}
            </Text>
          )
        );
      },
    },
  ];
 

  return (
    <>
      <ConfigProvider locale={locale}>
        <Cardpage>
        <Card>
          <Row justify="space-between">
            <Text type="h1" color="primary" bold className='page-title'>
              Çiftçi Talepleri
            </Text>
            <CSVLink data="" filename={`Tedarikçi-${moment().format('DD/MM/YYYY')}.csv`}>
              <Button className="mr" type="primary" size="large" disabled={isLoading}>
                Excel İndir
              </Button>
            </CSVLink>
          </Row>
          <Table
            columns={tableCols}
            size="middle"
            loading={isLoading}
            dataSource={farmerDiscountInvoice}
            pagination={{
              position: ["topRight", "none" as any],
              showLessItems: true,                          
              showSizeChanger: true,
              current: pNumber,
              pageSize: pSize,
              total:totalDataCount,                                                       
              onChange: handleShowSize,
            }}           
            locale={{emptyText: 'Veri Yok', filterReset: 'Sıfırla', filterConfirm: 'Filtrele'}}           
            rowKey={record => record.applicationNumber}
            onRow={(fund) => ({
              onClick: () => navigateFinancialDetail(fund),
            })}
            scroll={{
              x: 1000,
            }}
          />
        </Card>
        </Cardpage>
      </ConfigProvider>
    </>
  );
};

export default FinancialInstitiutions;