import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, notification, Spin, Modal } from 'antd';
import { ExclamationCircleOutlined, ReloadOutlined, HourglassOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from 'moment';
import 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../components/Text';
import Icon from '../../components/Icon/index';
import { fundStatusMap2Value } from '../../constants/funds';
import { convertToDotSeperated } from '../../utils/string';
import financialsApi from '../../Api/Financials';
import DetailPlaceholder from '../../components/Details/DetailsPlaceholder';
import { setFinancialData, setFinancialStatus, setInvoicesList } from '../../store/reducers/financialSlice';
import OfferCard from '../../components/OfferCard';
import { LeftSideBox, BottomSideBox, Header, TableBox, CardBox } from './styles';
import paths from '../../routes/paths';
import LocalStorageService from '../../services/LocalStorageService';

const FinancialDetails: React.VFC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { id, discountId }: { id: string; discountId: string } = useParams();
  const [invoiceGty, setInvoiceGty] = useState(0);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [select, setSelect] = useState(0);
  const [calculate, setCalculate] = useState<any>([]);
  const [selectInvoice, setSelectInvoice] = useState<any>([]);
  const [pageLoading, setPageLoading] = useState(false);

  const history = useHistory();
  const { discountInvoices, financial, invoicesList } = useSelector((state: any) => state.financials);
  const finanStatus: any = LocalStorageService.getStatusCode();
  const ftaxId = [
    {
      name:'DYatırım',
      vkn:'2651554884'
    },
    {
      name:'ŞekerBank',
      vkn:'8010048575'
    },
  ]

  const status = finanStatus && (fundStatusMap2Value[finanStatus]) || {};
  const statusColor = status?.color || 'smoke';
  const statusDesc = status?.desc || '';
  const taxId = LocalStorageService.getTaxId();

  const fetchFinancialbyId = async () => {
    setLoading(true);
    if (id) {
      const response: any = await financialsApi.fetchDiscountInvoiceById(id, discountId);
      setLoading(false);
      dispatch(setFinancialData(response.data.discountApplicationOffer));
    }
  };


  useEffect(() => {
    if (!finanStatus) {
      const found =
        discountInvoices &&
        discountInvoices.length > 0 &&
        discountInvoices.find((val: any) => val.financialInstitutionGuid === id);
      if (found) {
        dispatch(setFinancialStatus(found.status));
      }
    }
  }, [discountInvoices]);

  const getchosenDataTotal = (data: any) => {
    let totalAmount = 0;
    data.map((val: any) => {
      totalAmount += parseFloat(val.invoiceAmount);
      return val;
    });
    setInvoiceTotal(totalAmount)
    return totalAmount;
  };

  const getTotalCal = async (data: any) => {
    if (data.length < 1) {
      setCalculate([])
    } else {
      interface Invoces {
        invoiceAmount: number;
        invoiceTerm: string;
      }
      setPageLoading(true);
      const invoices: any = [];
      (data).map((item: any) => {
        const newItem = {} as Invoces;
        newItem.invoiceAmount = item.invoiceAmount
        newItem.invoiceTerm = moment.tz(item.invoiceTerm, "Europe/Istanbul").format()
        invoices.push(newItem)
        return true
      });

      try{
        const response = await financialsApi.getInvoiceCalculate(financial.applicationNumber, financial?.buyerId, invoices);
        if (response) {
          setCalculate(response.data)
          setPageLoading(false);
        }
      } catch (e:any) {
        if (e.data.Status === 400 && e.data.Type.includes('RateValueNotFoundException')) {
          notification.error({ message: '270 günden uzun vadeli faturalara teklif veremezsiniz.' });
          setCalculate([]);
        }
      }
    }
    return true
  }

  const rowSelection = {
    onChange: (selectedRowKeys:any, selectedRows:any) => {
      if (selectedRowKeys.length < 1) {
        setCalculate([])
      }

      setSelectInvoice(selectedRows);
      getTotalCal(selectedRows)
      setSelect(selectedRowKeys.length)
      interface InvoceUpdate {
        invoices: Array<Invoces>;
      }
      interface Invoces {
        id: string;
        status: string;
        invoiceAmount: number;
        invoiceNumber: string;
      }
      const newData = {} as InvoceUpdate;
      newData.invoices = [];

      (financial?.invoices || []).map((item: any) => {
        const newItem = {} as Invoces;
        newItem.id = item.id;
        newItem.status = item.status;
        newItem.invoiceAmount = item.invoiceAmount;
        newItem.invoiceNumber = item.invoiceNumber;
        newData.invoices.push(newItem)
        return true
      });

      newData.invoices.map((dataItem: any) => {
        if (selectedRows.find((item: any) => dataItem.id === item.id)) {
          dataItem.status = 'SECILDI'   //  eslint-disable-line no-param-reassign               
        } else {
          dataItem.status = 'SECILMEDI'    //  eslint-disable-line no-param-reassign              
        }
        return true;
      })
      dispatch(setInvoicesList(newData))
    }
  };

  const changeCancel = async () => {
    const response = await financialsApi.setCancelDiscountApplication(financial?.applicationNumber);
    if (response.status === 204) {
      history.push(paths.default);
    }
  };


  useEffect(() => {
    const chosenData = (finanStatus === 'YENI_TALEP' ? invoicesList?.invoices || [] : financial?.invoices || []).filter((i: any) => i.status === 'SECILDI' || i.status === 'ON_ODEME_YAPILDI')
    setInvoiceGty(chosenData.length);
    getchosenDataTotal(chosenData);
  }, [invoicesList, financial?.invoices])

  const handleOffer = async () => {
    // console.log('test');
  };


  const sortData = (data: any) => {
    return data.slice().sort((a: any, b: any) => b.isAccepted - a.isAccepted);
  }

  const statusOutput = (value: any) => {
    if (value === 'ON_ODEME_YAPILDI') {
      return <h4 style={{ color: '#1970e3' }}>Ön Ödeme Yapıldı</h4>
    }
    if (value === 'ODENDI') {
      return <h4 style={{ color: '#62b61f' }}>Ödendi</h4>
    }
    if (value === 'SECILDI') {
      return <CheckCircleOutlined style={{ color: '#62b61f', fontSize: 22, marginLeft: 25 }} />
    }
    if (value === 'SECIM_YAPILMADI') {
      return <HourglassOutlined style={{ color: '#f5ee12', fontSize: 22, marginLeft: 25 }} />
    }
    if (value === 'IPTAL_EDILDI') {
      return <CloseCircleOutlined style={{ color: '#cc2c2c', fontSize: 22, marginLeft: 25 }} />
    }
    if (value === '') {
      return ''
    }
    if (value === 'SECİLMEDİ') {
      return <CloseCircleOutlined style={{ color: '#cc2c2c', fontSize: 22, marginLeft: 25 }} />
    }
    return true
  }

  const tableCols = [
    {
      title: 'Fatura No',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'Hash Kodu',
      dataIndex: 'hashCode',
      key: 'hashCode',
    },
    {
      title: 'Fatura Tarihi',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (date: any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: 'Fatura Tutarı',
      dataIndex: 'invoiceAmount',
      key: 'invoiceTotal',
      render: (price: number) => `${convertToDotSeperated(price)} ₺`,
    },
    {
      title: 'Fatura Vadesi',
      dataIndex: 'invoiceTerm',
      key: 'invoiceTerm',
      render: (date: any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: 'Onay Durumu',
      dataIndex: 'status',
      className: finanStatus !== 'YENI_TALEP' ? '' : 'row-hide',
      render: (value: any) => statusOutput(value)
    }
  ];

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    changeCancel();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const xlsxDonwload = async () => {
    setLoading(true);
    try {
      const response:any = await financialsApi.getXlsxDonwloadApi(financial?.discountApplicationId);
      if (response) {
        setLoading(false);
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(response?.data?.xlsxFileForApplicationDetail)}`;
        link.setAttribute('download', `Başvuru Detayı.xlsx`);

        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      }
    }catch(e:any){
      // console.log('hata',e)
    }
  }

  useEffect(() => {
    if (id) {
      fetchFinancialbyId();
      setInvoiceGty(0);
      setInvoiceTotal(0);
    }
  }, [id]);

  useEffect(() => {
    if (finanStatus === 'YENI_TALEP') {
      dispatch(setInvoicesList([]))
    }
  }, [finanStatus]);

  if (loading) return <DetailPlaceholder />;
  return (
    <CardBox>

      <Row>
        <Header>
          <Text className="head-title">{financial?.applicationNumber || '-'} No &apos;lu Başvuru Detayı</Text>
        </Header>
      </Row>
      <Row>
        <Col span={7}>
          <LeftSideBox>
            <Row className={`status-box 
                  ${status.value === 'YENI_TALEP' && 'new-request'}
                  ${status.value === 'SATICI_ONAYINDA' && 'sales-person'}
                  ${status.value === 'TEMLIK_EDILDI' && 'offered'}
                  ${status.value === 'SATICI_TARAFINDAN_ONAYLANDI' && 'approved'}
              `}>
              <div style={{ display: 'flex' }}>
                {status.value === 'YENI_TALEP' && <HourglassOutlined style={{ fontSize: '24px', color: 'green' }} />}
                {status.value === 'SATICI_TARAFINDAN_ONAYLANDI' && <Icon style={{ fontSize: '24px', color: '#d335e5d9' }} icon={['far', 'angry']} />}
                {status.value === 'TEMLIK_EDILDI' && <Icon style={{ fontSize: '24px', color: '#f5771fd9' }} icon={['far', 'thumbs-up']} />}
                {status.value === 'SATICI_ONAYINDA' && <ReloadOutlined style={{ fontSize: '24px', color: 'blue' }} />}

                <Text className="status-title" style={{ color: statusColor }}>
                  {status.text}
                </Text>
              </div>
              <ExclamationCircleOutlined style={{ fontSize: '20px', color: '#727272' }} />
            </Row>
            <Row className="item-box">
              <Col>
                <Row className="item-row" style={{ marginBottom: '10px' }}>
                  <p>{statusDesc}</p>
                </Row>
                {
                  taxId === ftaxId[0].vkn || taxId === ftaxId[1].vkn ? (
                    <Row className="item-row" style={{ justifyContent: 'center' }}>
                      <Button type="primary" size="large" onClick={xlsxDonwload} loading={loading} style={{ marginBottom: '8px' }} >Excel İndir</Button>
                    </Row>
                  ) : ''
                }

                <Row className="item-row">
                  <Text className="item-title"> İşlem Tarihi</Text>
                  <Text className="item-value">{moment(financial?.invoiceDate).format('DD/MM/YYYY') || '-'}</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Toplam Fatura Tutarı</Text>
                  <Text className="item-value">{financial?.invoicesTotal && convertToDotSeperated(financial?.invoicesTotal)} ₺</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Ortalama Vade</Text>
                  <Text className="item-value">{financial?.averageMaturity} Gün</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Fatura Adet</Text>
                  <Text className="item-value">{financial?.invoicesCount || '-'}</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Tedarikçi</Text>
                  <Text className="item-value">{financial?.supplierTitle || '-'}</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Tedarikçi VKN</Text>
                  <Text className="item-value">{financial?.supplierTaxNumber || '-'}</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Tedarikçi Riski</Text>
                  <Text className="item-value">{financial?.supplierRisk && convertToDotSeperated(financial?.supplierRisk) || '-'} ₺</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Tedarikçi Limiti</Text>
                  <Text className="item-value">{financial?.supplierLimit && convertToDotSeperated(financial?.supplierLimit) || '-'} ₺</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Alıcı</Text>
                  <Text className="item-value">{financial?.buyerTitle || '-'}</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Alıcı VKN</Text>
                  <Text className="item-value">{financial?.buyerTaxNumber || '-'}</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Alıcı Riski</Text>
                  <Text className="item-value">{financial?.buyerRisk && convertToDotSeperated(financial?.buyerRisk)} ₺</Text>
                </Row>
                <Row className="item-row">
                  <Text className="item-title">Alıcı Limiti</Text>
                  <Text className="item-value">{financial?.buyerLimit && convertToDotSeperated(financial?.buyerLimit)} ₺</Text>
                </Row>
                <Row className="item-row">
                  {
                    status.value === 'YENI_TALEP' && (
                      <Row className="item-row" style={{ justifyContent: 'center' }}>
                        <Button
                          // onClick={changeCancel} 
                          onClick={() => showModal()}
                          type="primary"
                          style={{ marginBottom: '10px', backgroundColor: '#f00', minWidth: '120px', border: 'none' }}
                        >Başvuruyu İptal Et</Button>
                      </Row>
                    )
                  }
                </Row>
              </Col>
            </Row>

          </LeftSideBox>
        </Col>
        <Col span={17} style={{ position: 'relative' }}>
          <Spin tip="Hesaplanıyor.." spinning={pageLoading}>
            <TableBox>
              <Row>
                {financial?.invoices?.length > 0 && (
                  <Col span={24}>
                    <Table
                      style={{ marginTop: '32px' }}
                      className={status.value === 'YENI_TALEP' ? '' : 'all-select-table'}
                      scroll={{ x: 800, y: 400 }}
                      rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                      }}
                      pagination={false}
                      rowKey="invoiceNumber"
                      dataSource={sortData(financial?.invoices)}
                      columns={tableCols}
                      rowClassName={(record) =>
                        record.status === 'SECIM_YAPILMADI' ? '' : 'invoice-none'
                      }
                    />
                  </Col>
                )}
              </Row>

            </TableBox>
            <BottomSideBox>
              <Row>
                <div className='header-box'>
                  <div><Text className='header-title'>Toplam Seçilen Fatura</Text></div>
                  <div className='header-table'>
                    <div>
                      <Text className='text-title'>Adet</Text>
                      <span>{invoiceGty}</span>
                    </div>
                    <div>
                      <Text className='text-title' >Tutar</Text>
                      <span>{invoiceTotal && convertToDotSeperated(invoiceTotal)} ₺</span>
                    </div>
                    <div>
                      <Text className='text-title'>Ortalama Vade</Text>
                      <span>{finanStatus === 'YENI_TALEP' ?
                        calculate?.averageMaturity ? calculate?.averageMaturity : '0'
                        :
                        financial?.averageMaturity && financial?.averageMaturity
                      }</span>
                    </div>
                  </div>
                </div>
                {finanStatus !== 'YENI_TALEP' && (
                  <div className='header-box'>
                    <div><Text className='bid-amount'><span>Teklif Tutarı : </span>
                      {financial?.amount && convertToDotSeperated(financial?.amount)} ₺</Text></div>
                  </div>
                )}

              </Row>
              <Row style={{ marginTop: '20px' }}>
                {finanStatus === 'YENI_TALEP' && (
                  <OfferCard
                    invoiceTotalAmount={`${financial?.invoicesTotal || 0}`}
                    offerCharge={0}
                    onOffer={handleOffer}
                    invoiceData={invoicesList.invoices}
                    selectItem={select}
                    totalPrice={calculate?.calculatedSumAmount || 0}
                    invoiceGty={invoiceGty}
                    applicationNumber={`${financial?.applicationNumber || 0}`}
                    calculate={calculate}
                    selectInvoice={selectInvoice}
                    type='1'
                  />
                )}

              </Row>
            </BottomSideBox>
          </Spin>
        </Col>
      </Row>
      <Modal
        open={open}
        className="supplier-transaction"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
      >
        <p> Başvuruyu iptal etmek istediğinizden emin misiniz?</p>
      </Modal>
    </CardBox>

  );
};

export default FinancialDetails;
