const paths = {
  default: '/',
  login: '/login',
  supplierRequests:'/supplier-requests',
  farmerRequests:'/farmer-requests',
  farmerRequestsDetail: '/agriculturists/discount-application/:discountId',

  passwordRecovery: '/password-recovery',
  createPassword: '/password/create/:createPasswordToken',
  resetPassword: '/password/update/:id/:forgetPasswordToken',
  financialDetail: '/financial/:id/:discountId',
  dailyRates :'/daily-rates',
  dueInvoices :'/due-invoices',
  pendingCollection :'/pending-collection',
  buyerTransactions :'/buyer-transactions',
  twoFactor: '/two-factor',
  user: '/user',
  passwordChange: '/password-change',

  getFinancialDetailPath(id: any, discountId: any) {
    return `/financial/${id}/${discountId}`;
  },
  getFarmerRequestsDetails( discountId: any) {
    return `/agriculturists/discount-application/${discountId}`;
  },
};

export default paths;
