import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useLocation, RouteProps} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Login from '../pages/Login';
import PasswordRecovery from '../pages/Login/PasswordRecovery';
import TwoFactor from '../pages/Login/TwoFactor';
import ResetPassword from '../pages/Login/ResetPassword';
import CreatePassword from '../pages/Login/CreatePassword';
import FinancialInstitutions from '../pages/financialInstitutions';
import User from '../pages/User';
import paths from './paths';
import FinancialDetails from '../pages/financialDetails';
import DailyInterestRates from '../pages/dailyInterestRates';
import DueInvoices from '../pages/dueInvoices';
import PendingCollection from '../pages/pendingCollection';
import BuyerTransactions from '../pages/buyerTransactions';
import PaswordUpdate from '../pages/Login/PaswordUpdate';
import Home from '../pages/Home';
import FarmerRequests from '../pages/FarmerRequests';
import FarmerRequestsDetails from '../pages/FarmerRequestsDetails';

const ProtectedRoute: React.FC<RouteProps> = ({...props}) => {
  const {isLoggedIn} = useSelector((state: any) => state.user);

  return isLoggedIn ? <Route {...props} /> : <Redirect to={paths.login} />;
};
const GuestRoute: React.FC<RouteProps> = ({...props}) => {
  const {isLoggedIn} = useSelector((state: any) => state.user);

  return !isLoggedIn ? <Route {...props} /> : <Redirect to="/" />;
};

const TwoFactorRoute: React.FC<RouteProps> = ({...props}) => {
  const {userEmail} = useSelector((state: any) => state.user);
  
  return userEmail ? <Route {...props} /> : <Redirect to="/"  />;
};


const Routes: React.VFC = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => window.scroll({top: 0, behavior: 'smooth'}));
  }, [location]);

  return (
    <Switch>
      <GuestRoute path={paths.login} exact component={Login} />
      <GuestRoute path={paths.passwordRecovery} component={PasswordRecovery} />
      <GuestRoute path={paths.createPassword} component={CreatePassword} />
      <GuestRoute path={paths.resetPassword} component={ResetPassword} />
      <TwoFactorRoute path={paths.twoFactor} component={TwoFactor} />

      <ProtectedRoute path={paths.default} exact component={Home} />

      <ProtectedRoute path={paths.supplierRequests} exact component={FinancialInstitutions} />
      <ProtectedRoute path={paths.farmerRequests} exact component={FarmerRequests} />
      <ProtectedRoute path={paths.farmerRequestsDetail} exact component={FarmerRequestsDetails} />

      <ProtectedRoute path={paths.financialDetail} exact component={FinancialDetails} />
      <ProtectedRoute path={paths.passwordChange} exact component={PaswordUpdate} />
      {/* <ProtectedRoute path={paths.fundDetail} component={FundDetail} />
      <ProtectedRoute path={paths.notifications} component={Notifications} />
      <ProtectedRoute path={paths.myOffers} component={MyOffers} /> */}
      <ProtectedRoute path={paths.user} component={User} />
      <ProtectedRoute path={paths.dailyRates}  component={DailyInterestRates} />
      <ProtectedRoute path={paths.dueInvoices}  component={DueInvoices} />
      <ProtectedRoute path={paths.pendingCollection}  component={PendingCollection} />
      <ProtectedRoute path={paths.buyerTransactions}  component={BuyerTransactions} />
      <Redirect to={paths.default} />
    </Switch>
  );
};

export default Routes;
