import React,{useState} from 'react';
import {Col,  Row, Form, notification, Modal} from 'antd';
import {useHistory} from 'react-router-dom';
import Card from '../Card';
import InputNumber from '../InputNumber';
import Button from '../Button';
import financialsApi from '../../Api/Financials';
import farmerApi from '../../Api/Farmer';
import {convertToDotSeperated} from '../../utils/string';
import ContentContainer from '../ContentContainer';
import paths from '../../routes/paths';

// TODO: refactor offerType logics (create enum for offerType)

interface Props {
  // fundId: string;
  invoiceTotalAmount?: string;
  offerCharge: number;
  onOffer: () => Promise<void>;
  invoiceData : Array<Props>;
  selectItem: number;
  totalPrice : string;
  applicationNumber :string;
  invoiceGty: number;
  calculate: Array<any>;
  selectInvoice: Array<any>;
  type: string;
}

const OfferCard: React.FC<Props> = ({type, invoiceData, selectInvoice, calculate, selectItem, offerCharge, applicationNumber, totalPrice , invoiceGty, invoiceTotalAmount = '0', onOffer}) => {
  
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [offerAmount, setOfferAmount] = useState<string>('0');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedInvoiceTotal = selectInvoice.reduce((a, c) => a + c.invoiceAmount, 0)
 
  const makeOffer = async () => {
    if(type === '1'){
      const conditionalApprovalNote = '';
      if (invoiceGty === 0) {
        notification.success({ message: 'Lütfen teklif vermek istediğiniz faturayı/ları seçiniz.' })
      } else {
        const value = {
          offerAmount: offerAmount === '0' || offerAmount === '' ? parseFloat(totalPrice).toFixed(2).toString().replace(/\./g, ',') : offerAmount.replaceAll('.', ''),
          invoiceTotalAmount: parseFloat(invoiceTotalAmount).toFixed(2).toString().replace(/\./g, ','),
          conditionalApprovalNote: !conditionalApprovalNote ? '' : conditionalApprovalNote,
        }
 
        if (value.offerAmount === undefined || value.offerAmount === '0,00') {
          notification.success({ message: 'Teklif tutarı girmelisiniz.' })
        } else {
          try {
            setLoading(true);
            await financialsApi.makeOffer(applicationNumber, {
              ...value,
              offerAmount: value.offerAmount || '',
              conditionalApprovalNote: value.conditionalApprovalNote,
              invoiceTotalAmount: value.invoiceTotalAmount || '',
              offerCharge,
              invoices: invoiceData,
            });
  
            notification.success({ message: 'Gün sonuna kadar geçerli olan teklifiniz başarı ile KOBİ’mize iletildi. Onaylanması durumunda sizi bilgilendireceğiz.' });
            history.push(paths.supplierRequests);
            window.location.reload();  
          }
          catch (e: any) {
            if (e.status === 400 && e.data.Type.includes('OfferAmountNotHigherThanInvoiceException')) {
              notification.warning({ message: 'Teklif tutarı fatura tutarından fazla olmamalıdır.' });
            }
            if (e.status === 409 && e.data.Type.includes('DuplicateOfferFoundException')) {
              notification.warning({ message: 'Bu başvuru için mevcut bir teklifiniz bulunmaktadır!' });
            }
            if (e.status === 400 && e.data.Type.includes('OfferAmountCannotBeZeroException')) {
              notification.warning({ message: 'Teklif Tutarı 0 dan büyük olmalıdır.' });
            }
          }
          finally {
            if (onOffer) await onOffer();
            setLoading(false);
          }
        }
      }
    }else{
      const conditionalApprovalNote = '';
      if (invoiceGty === 0) {
        notification.success({ message: 'Lütfen teklif vermek istediğiniz faturayı/ları seçiniz.' })
      } else {
        const value = {
          offerAmount: offerAmount === '0' || offerAmount === '' ? parseFloat(totalPrice).toFixed(2).toString().replace(/\./g, ',') : offerAmount.replaceAll('.', ''),
          invoiceTotalAmount: parseFloat(invoiceTotalAmount).toFixed(2).toString().replace(/\./g, ','),
          conditionalApprovalNote: !conditionalApprovalNote ? '' : conditionalApprovalNote
        }
  
        if (value.offerAmount === undefined || value.offerAmount === '0,00') {
          notification.success({ message: 'Teklif tutarı girmelisiniz.' })
        } else {
          try {
            setLoading(true);
            let newData: any = [];
            newData = invoiceData.map((item: any) => {
              return (
                {
                  id: item.id,
                  status: item.status,
                  invoiceNumber:item.invoiceNumber,
                }
              )
            })

            await farmerApi.makeOffer(applicationNumber, {
              ...value,
              offerAmount: value.offerAmount || '',
              conditionalApprovalNote: value.conditionalApprovalNote,
              invoiceTotalAmount: value.invoiceTotalAmount || '',
              offerCharge,
              invoices: newData,
            });
  
            notification.success({ message: 'Gün sonuna kadar geçerli olan teklifiniz başarı ile KOBİ’mize iletildi. Onaylanması durumunda sizi bilgilendireceğiz.' });
            history.push(paths.farmerRequests);
            window.location.reload(); 
          }
          catch (e: any) {
          
            if (e.status === 400 && e.data.Type.includes('OfferAmountNotHigherThanInvoiceException')) {
              notification.warning({ message: 'Teklif tutarı fatura tutarından fazla olmamalıdır.' });
            }
            if (e.status === 409 && e.data.Type.includes('DuplicateOfferFoundException')) {
              notification.warning({ message: 'Bu başvuru için mevcut bir teklifiniz bulunmaktadır!' });
            }
            if (e.status === 400 && e.data.Type.includes('OfferAmountCannotBeZeroException')) {
              notification.warning({ message: 'Teklif Tutarı 0 dan büyük olmalıdır.' });
            }
          }
          finally {
            if (onOffer) await onOffer();
            setLoading(false);
          }
        }
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    makeOffer()
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Card className=" d-block" style={{padding:'0', margin:'0 10px'}} >
      <ContentContainer verticalPadding={false} type="inner">
        <Form form={form} name="offer" >
          <Row gutter={40}>
            <Col flex={1} style={{maxWidth: 500}}>
              <Form.Item
                name="offerAmount"
                className="my input-my"                 
                rules={[
                  {
                    required: false,
                    message: 'Lütfen teklif tutarı giriniz!',      
                  },
                ]}
              >
                <InputNumber 
                  onChange={(val:any) => setOfferAmount(val)}   
                  placeholder={selectItem === 0 ? 'Teklif ver' : convertToDotSeperated(totalPrice)}  
                 disabled={selectItem<1 || calculate.length <= 0}
                  />
              </Form.Item>
          </Col>
            <Col>
               
              <Form.Item className="my">
                <Button
                  type="primary"
                  // htmlType="submit"
                  loading={loading}
                  onClick={showModal}
                  block
                  disabled={selectItem<1 || calculate.length <= 0}
                >
                 Teklif Gönder
                </Button>
              </Form.Item>
            </Col>

           
          </Row>
        </Form>
          <Modal 
            okText='Evet'
            cancelText='Hayır'
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}>
            <div className='selectInvoice-box'>
              <ul>
                {
                  selectInvoice && selectInvoice.map((item:any) => {
                    return(
                      <li>{item.invoiceNumber}</li>
                    )
                  })
                }
              </ul>
            </div>
            <p style={{marginBottom:'0'}}>numaralı ve toplam tutarı {convertToDotSeperated(selectedInvoiceTotal)} TL  olan {selectInvoice && selectInvoice.length} adet fatura için 
            &nbsp; 
             {offerAmount === '0' || offerAmount === '' ? convertToDotSeperated(totalPrice): offerAmount }  TL teklif veriyorsunuz. Teklifin tedarikçiye iletilmesini onaylıyor musunuz?</p>
        </Modal>
      </ContentContainer>
    </Card>
    
  );
};

export default OfferCard;
