import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Row, Col, Form, Input, notification} from 'antd';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import LoginStyles, {Logo} from '../styles';
import LogoFonRadar from '../../../assets/logo-big.png';
import userApi from '../../../Api/User';
import paths from '../../../routes/paths';
import {validatePassword} from '../../../utils/validators'

const PasswordRecovery = () => { 
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const onUpdatePass = async (formValues: {newPassword: string; confirmPassword: string}) => {
    setLoading(true);
    try {
      const {newPassword, confirmPassword} = formValues;
      if (newPassword && confirmPassword && newPassword === confirmPassword) {
        if (location.pathname.split('/')[2] === 'update') {
          await userApi.updatePassword({
            passwordUser: location.pathname.split('/')[3],
            passwordToken: location.pathname.split('/')[4],
            newPassword,
          });
        } 
        notification.success({
          message: 'Şifreniz güncellendi!',
          description: 'Yeni şifrenizi kullanarak giriş yapabilirsiniz.',
        });
        history.push(paths.login);
      } else {
        setLoading(false);
        notification.error({
          message: 'Şifre ve şifreyi onayla eşleşmiyor',
          duration: 3,
        });
      }
    } catch(e:any){
      if(e.status === 400 && e.data.Messages ){
        const length = e.data.Messages.length - 1;
        let x = 0;
        for( x=0; x < length + 1 ; x+=1){
            notification.warning({
                message: ` ${e.data.Messages[x]}`  
            });
        } 
      }
      if(e.data.Status === 409 && e.data.Type.includes('LastThreePasswordMatchedException')){
        notification.warning({
            message: `Yeni parola son üç parola ile aynı olamaz.`,
        });
    }
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <LoginStyles>
      <Row justify="center">
        <Col xs={24} md={12} lg={10}>
          <Card>
            <Logo className="mb-big" src={LogoFonRadar} alt="Depar.io" />
            <Text type="h3" color="primary" >
              Şifre Yenile
            </Text>
            <p>Lütfen belirlemiş olduğunuz yeni şifrenizi onaylamak için iki defa giriniz.</p>

            <Form name="passwordRecovery" onFinish={onUpdatePass}>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password type="password" placeholder="Yeni Şifre" />
              </Form.Item>
              <Form.Item
                style={{margin: '0px'}}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password type="password" placeholder="Yeni Şifreyi Tekrarla" />
              </Form.Item>
              <Form.Item>
                <Button
                  className="mt-big"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  block
                >
                  Şifremi Güncelle
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </LoginStyles>
  );
};

export default PasswordRecovery;
