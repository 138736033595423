import { apiV1 } from '..';

export default {

  async getAgriculturistDiscountApi(pSize: any, pNumber:any) {
    return apiV1.get(`agriculturists/agriculturist-discount-applications?pageNumber=${pNumber}&pageSize=${pSize}`);
  },

  async getAgriculturistDiscountDetailApi(discountId: any) {
    return apiV1.get(`agriculturists/discount-application/${discountId}`); 
  },

  async makeOffer(discountId: any, offer: any) {
    return apiV1.post(`agriculturists/${discountId}/offer`, offer);
  },
 
  async setCancelDiscountApplication( discountapplication: any) {  
    return apiV1.patch(` agriculturists/${discountapplication}/cancel`);
  },


};
